import { useEffect, useState } from 'react';
import cn from 'classnames';
import { useRouter } from 'next/router';
import {
  checkIsWindowsOS,
  countryCodeEmoji,
  reformatCountryCodeToCountryName,
} from 'lib/utils/helpers';
import { keys, set, get } from 'lib/utils/localStorage';
import { useSettings } from 'contexts';
import { useUI, useCustomer, useLogoutCustomer } from 'hooks';
import styles from './country-selector.module.scss';
import ChevronUp from '../../shared/chevron-up';
import ChevronDown from '../../shared/chevron-down';

const CountrySelectorModal = ({ globalModules, pageData, locale }) => {
  const extractedCountries = Object.values(globalModules?.firstcountryselectorlocales);
  const allLocales = extractedCountries.map(country => country.countryList).flat();
  const [userCountry, setUserCountry] = useState({});
  const [activeDropdown, setActiveDropdown] = useState(false);
  const [title, setTitle] = useState(globalModules?.countryselector?.chooseAnotherLocation?.text);
  const [countries] = useState(allLocales);
  const [showModal, setShowModal] = useState(false);
  const [isWindows, setIsWindows] = useState('');
  const router = useRouter();
  const { countryCode, setCountryCode } = useSettings();
  const { openLocalePopup } = useUI();
  const { customer } = useCustomer();
  const [logoutCustomer] = useLogoutCustomer(locale);
  const [countryOptions, setCountryOptions] = useState([]);

  useEffect(() => {
    setIsWindows(checkIsWindowsOS());
  }, []);

  const checkCountryVariants = country => {
    // if there are multiple countries with the same country code, show the language selector
    const countryVariants = countries.filter(
      c => c.countryCode.toLowerCase() === country?.countryCode?.toLowerCase()
    );
    if (countryVariants.length > 1) {
      setCountryOptions(countryVariants.length > 1 ? countryVariants : []);
      return true;
    }

    return false;
  };

  const setLanguage = country => {
    setUserCountry(country);
    setTitle(country.name);
    setCountryCode(country.countryCode.toLowerCase());
  };

  const setDefaultEn = () => {
    const internationalEnglishCountryObj = countries.find(
      country => country.name.includes('International') && country.name.includes('English')
    );
    setLanguage(internationalEnglishCountryObj);
    setCountryOptions([]);
  };

  useEffect(() => {
    if (userCountry?.countryCode) return;

    fetch('/api/user-location')
      .then(res => res.json())
      .then(location => {
        const countryMatch = countries?.find(
          c => c.countryCode.toLowerCase() === location.countryCode.toLowerCase()
        );
        if (countryMatch && countryMatch?.name) {
          setLanguage(countryMatch);
          checkCountryVariants(countryMatch);
        } else {
          setDefaultEn();
        }
      })
      .catch(() => {
        setDefaultEn();
      });
  }, [countries, userCountry]);

  useEffect(() => {
    if (!userCountry?.countryCode || !locale) {
      setShowModal(false);
      return;
    }

    if (router.query.from) {
      set(keys.USER_LOCALE_PREFERENCE, 'en');
      setShowModal(false);
      return;
    }

    const currentCountry = countries.find(c => c.slug?.replace(/\//g, '') === locale);
    const currentPrefferedCountry = get(keys.USER_LOCALE_PREFERENCE);

    if (currentPrefferedCountry === currentCountry?.slug?.replace(/\//g, '').toLowerCase()) {
      setShowModal(false);
      return;
    }

    if (currentCountry?.countryCode !== userCountry?.countryCode) {
      setShowModal(true);
    }
  }, [userCountry, countries, locale]);

  const getCountryFromCountryCode = cc => cc?.slice(-2);
  const countryName = reformatCountryCodeToCountryName(countryCode.toLowerCase());

  const getLocaleFromSlug = slug => slug?.replace(/\//g, '')?.toLowerCase();

  const handleLocaleApply = country => {
    // if external link is set, redirect to it
    if (country.externalLink) {
      document.location.href = country.externalLink;
      return;
    }

    const localeFromSlug = getLocaleFromSlug(country?.slug === '/' ? 'en' : country?.slug);
    const [pageSlug] = pageData.href.filter(
      ({ locale: hrefLocale }) => hrefLocale === localeFromSlug
    );

    if (country.slug === '/' && localeFromSlug === 'en') {
      openLocalePopup(country.slug);
      set(keys.USER_LOCALE_PREFERENCE, localeFromSlug);
      setShowModal(false);
    } else {
      set(keys.USER_LOCALE_PREFERENCE, localeFromSlug);
      setShowModal(false);
      if (customer) {
        logoutCustomer(customer?.customerAccessToken?.accessToken);
      }

      // using this instead of router.push to reload all klaviyo popups.
      const baseUrl = document.location.origin;
      const path = pageSlug?.slug?.startsWith('/') ? pageSlug?.slug : `/${pageSlug?.slug || ''}`;
      const newLocale = localeFromSlug === 'en' ? '' : `/${localeFromSlug}`;
      document.location.href = `${baseUrl}${newLocale}${path}`;
    }
  };

  const handleLocaleSelect = country => {
    setActiveDropdown(false);
    setLanguage(country);
    const hasVariants = checkCountryVariants(country);
    if (!hasVariants) handleLocaleApply(country);
  };

  if (showModal) {
    return (
      <div className={styles.ccSelectorModal}>
        <div className={styles.ccSelectorContainer}>
          <h2 className={styles.updateContent}>
            {globalModules?.countryselector?.updateContent?.text}
          </h2>
          <div className={styles.countryDetection}>
            {`${globalModules?.countryselector?.countryDetection?.text} `}
            <span>{countryName?.length > 2 ? countryName : userCountry?.name}</span>
            {globalModules?.countryselector?.chooseLocation?.text}
          </div>
          <div className={styles.controlsContainer}>
            <div
              className={styles.confirmationButtonContent}
              onClick={() => setActiveDropdown(!activeDropdown)}
              role="none"
            >
              <button type="button" className={cn(styles.button, styles.resetMargin)}>
                <>
                  {!isWindows &&
                    title !== globalModules?.countryselector?.chooseAnotherLocation?.text && (
                      <span className={styles.countryCode}>
                        {countryCodeEmoji(
                          getCountryFromCountryCode(userCountry?.countryCode || countryCode)
                        )}{' '}
                      </span>
                    )}
                  <span>{title}</span>
                </>
              </button>
              <div className={styles.chevronContainer}>
                {activeDropdown ? (
                  <ChevronUp width="24px" height="24px" />
                ) : (
                  <ChevronDown width="24px" height="24px" />
                )}
              </div>
              {activeDropdown && (
                <ul className={styles.locationsContainer}>
                  {countries.map(country => (
                    <button
                      key={country?.sys.id}
                      type="button"
                      className={styles.countrySelector}
                      onClick={() => {
                        handleLocaleSelect(country);
                      }}
                    >
                      {!isWindows && (
                        <span className={styles.countryCode}>
                          {countryCodeEmoji(getCountryFromCountryCode(country?.countryCode))}
                        </span>
                      )}{' '}
                      <span>{country?.name}</span>
                    </button>
                  ))}
                </ul>
              )}
            </div>

            <button type="button" onClick={() => handleLocaleApply(userCountry)}>
              <div className={styles.confirmContent}>
                <span>{globalModules?.countryselector?.confirmContent?.text}</span>
              </div>
            </button>

            {countryOptions?.length > 1 && (
              <div className={styles.languageSelector}>
                {countryOptions.map(country => (
                  <button
                    type="button"
                    className={styles.languageOption}
                    onClick={() => setLanguage(country)}
                  >
                    <input
                      className={styles.radio}
                      type="radio"
                      name="language"
                      checked={userCountry?.sys?.id === country?.sys?.id}
                    />
                    <span className={styles.label}>{country.defaultLanguage.toUpperCase()}</span>
                  </button>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  return null;
};

export default CountrySelectorModal;
